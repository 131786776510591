<template>
<div>
    <b-button
      class="btncat"
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-categorie
      variant="outline-primary"
    >
      Ajouter une catégorie
    </b-button>
    <b-modal
      id="modal-categorie"
      ref="my-modal"
      title="Ajouter une catégorie"
      ok-title="Ajouter"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk, addCat"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Nom de la catégorie"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            :v-model="newCat"
            id="new-todo"
            placeholder="Nom de votre catégorie"
          />
        </b-form-group>
        
      </form>
    </b-modal>
  </div>
</template>
<script>
import { BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import states from "../data/data.json";
import axios from 'axios'
const baseURL = "http://localhost:3000/categories";
export default {
    name: 'modal-add-cat',

  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
    computed: {
        
    data() {
     return{
       categories: [],
       newCat: ""

     };
    },
    sous() {
      return states.sousCategories.map((item) => {
        return item;
      });
    },
     ouvrages() {
      return states.ouvrage.map((item) => {
        return item;
      });
    },
    
  },
 
   async created(){
    try{
       const res = await axios.get(baseURL);
  this.categories = res.data
    }catch (e){
      console.error(e)
    }
   },
   
  methods: {
    
    async addCat(){
      const res = await axios.post(baseURL, {name: this.newCat});
      this.categories = [...this.categories, res.data];
      this.newCat= "";
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
     handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
   
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

    
  },
}
</script>
<style lang="scss" scoped>
.btncat{
    position:absolute !important;
    bottom: -20px !important;
    left:27px;
}
ul{
    height: 50px;
    width: 50px;
    display: block;
    li{
        display: block;
        border: solid 1px black;
        color: black;
        padding: 10px;
        height: 30px;
        width: 100px;
    }
}
</style>