import axios from '@axios'
import {parse, stringify} from 'flatted';

//var url = "http://127.0.0.1:8000/api";
var url = "https://api.pedra.fr/api";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCatalogues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(url+'/catalogues', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCatalogue(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(url+`/catalogues/2`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFamille(ctx, familleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/catalogue/famille', { famille: familleData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delFamille(ctx , familleData){
      console.log('axios famille supprime')
      var id = familleData.id
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/catalogue/famille/'+ id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategorie(ctx, catData) {
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/catalogue/categorie', { cat: catData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delCategorie(ctx , catData){
      console.log('axios cat supprime')
      console.log(catData)
      var id = catData.id
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/catalogue/categorie/'+ id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOuvrage(ctx, ouvrage) {
      console.log('axios store')
      //ouvrage.tags = stringify(ouvrage.tags)
      console.log(ouvrage)
      return new Promise((resolve, reject) => {
        axios
          .post(url+'/ouvrages', { ouvrage: ouvrage })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    majOuvrage(ctx, ouvrage) {
      console.log('axios maj')
      console.log(ouvrage)
      var id = ouvrage.id
      return new Promise((resolve, reject) => {
        axios
          .put(url+'/ouvrages/'+id, { ouvrage: ouvrage })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delOuvrage(ctx , ouvrage){
      console.log('axios supprime')
      console.log(ouvrage)
      var id = ouvrage.id
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .delete(url+'/ouvrages/'+ id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    }
  },
}
