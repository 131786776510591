<template>
<div>
    <b-button
      class="btncat"
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-ouvrage
      variant="outline-primary"
    >
      Ajouter un ouvrage
    </b-button>    
    <!-- modal -->
    <b-modal
      id="modal-ouvrage"
      ref="my-modal-ouvrage"
      title="Ajouter un ouvrage"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    > 
    <div class="contenaire-form">
      <form
        ref="form"
        class="for"
      >
     
        <b-form-group
        class="name"
          label="Nom de l'ouvrage"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            required
          />
        </b-form-group>
         <b-form-group
         class="asso"
          label="Associé à"
          label-for="cat"
          invalid-feedback="Name is required"
        >
        
          <select class="select" v-model="testVal">
    <option v-for="cat in categorie" :key="cat.id" :value="cat">{{cat.name}}</option>
</select>
        </b-form-group>
         <b-form-group
         class="asso"
          label="Catégorie des produits"
          label-for="cat"
          invalid-feedback="Name is required"
        >
         <v-select
    v-model="categorie"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    multiple
    label="title"
    :options="option"
  />
        </b-form-group>

        <b-form-group
        class="asso"
          label="unité"
          label-for="unite">
             <b-form-select
             id="unite"
      v-model="selected"
      :options="unite"
    />
</b-form-group>
          <b-form-group
        class="asso"
          label="Temps"
          label-for="temps-input"
          invalid-feedback="la durée est requise"
        >
          <b-form-input
            id="temps-input"
            v-model="time"
            required
          />
        </b-form-group>
        <b-form-group class="name">
          <label for="textarea-default">Description</label>
    <b-form-textarea
      id="textarea-default"
      placeholder="Description de l'ouvrage "
      rows="3"
    />
    </b-form-group>
     
      </form>
      <form ref="form"
      class="assos">
      <h1>Calcul du prix</h1>
      <b-form-group
        class="asso"
          label="Prix HT"
          label-for="prix"
          invalid-feedback="la durée est requise"
        >
          <b-form-input
            id="prix"
            v-model="time"
            required
          />
        </b-form-group>
        <b-form-group
        class="asso"
          label="Chute (%)"
          label-for="chute"
          invalid-feedback="la durée est requise"
        >
          <b-form-input
            id="chute"
            v-model="time"
            required
          />
        </b-form-group>
        <b-form-group
        class="name"
          label="Temps de pose supplémentaire"
          label-for="tempsSup"
          invalid-feedback="la durée est requise"
        >
          <b-form-input
            id="tempsSup"
            v-model="time"
            required
          />
        </b-form-group>
      </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import states from "../data/data.json";


export default {
    name: 'Modal-ouvrage',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BModal,
    BFormTextarea,
    vSelect,
  },
   directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    selected: Array
  },
   produits() {
    return {
      dir: 'ltr',
      selected: [
        { title: 'Square' },
        { title: 'Romboid' },
      ],
    }
  },
  data: () => ({
    name: '',
    nameState: null,
    submittedNames: [],
    options: [
        { value: null, text: 'Please select some item' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Default Selected Option' },
        { value: 'c', text: 'This is another option' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      unite: [
        {
          value: null,
          text: 'Sélectionnez votre unité'
        },
        {
          value: 'a',
          text: 'forfait'
        },
        {
          value: 'b',
          text: 'm2'
        }, {
          value: 'c',
          text: 'm3'
        },
         {
          value: 'd',
          text: 'linéaire'
        },
         {
          value: 'e',
          text: 'kg'
        },
         {
          value: 'f',
          text: 'u'
        },
      ]
  }),
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal-ouvrage'].toggle('#toggle-btn')
      })
    },
  },
  computed: {
  categorie() {
      return states.categories.map((item) => {
        return item;
      });
    },
  },
  
}
</script>
 

<style lang="scss" scoped>

.btncat{
    position:absolute !important;
    bottom: -20px !important;
    left: 45vw;
}

.for{
  display: flex;
  flex-wrap: wrap;
  .name{
    width: 100%;
    padding: 5px;
  }
  .asso{
  width: 50%;
  padding: 5px;

}
}

.select{
  width: 100%;
      border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 0.438rem 2rem 0.438rem 1rem;
    height: 38px;

}


</style>
<style lang="scss">
.modal-content{
  width: 66vw;
      transform: translate(-26%);
}
</style>

