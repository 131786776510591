<template>
<div>


             <b-button
 class="btncat"
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-souscategorie
      variant="outline-primary"
    >
      Ajouter une sous-catégorie
    </b-button>
         
    <!-- modal -->
    <b-modal
      id="modal-souscategorie"
      ref="my-modal"
      title="Ajouter une sous-catégorie"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
       
      >
        <b-form-group
          
          label="Nom de la Sous-catégorie"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
           
            required
          />
      

        </b-form-group>
        <b-form-group
          
          label="Nom de la catégorie associé"
          label-for="cat"
          invalid-feedback="Name is required"
        >
         
       <select class="select" v-model="testVal">
    <option id="cat" v-for="cat in categorie" :key="cat.id" :value="cat">{{cat.name}}</option>
</select>

        </b-form-group>
        <button @click="test">add</button>
          
      </form>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BFormSelect, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import states from "../data/data.json";

export default {
    name: 'Modal-souscategorie',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormSelect,
  },
   cat() {
    return {
      selected: null,
      
    }
  },
    computed: {
  categorie() {
      return states.categories.map((item) => {
        return item;
      });
    },
  },
  
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  
  methods: {
    test(){
      const data = states.categories;
      console.log(data);
      const cat = {
        id: 99,
        name: "tartiflette",
        slug: "tartiflette"
      }
      states.categories.push(cat);
      console.log(states.categories)
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>
 

<style lang="scss" scoped>

.btncat{
    position:absolute !important;
    bottom: -20px !important;
    left: 23vw;
}
.select{
  width: 100%;
      border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 0.438rem 2rem 0.438rem 1rem;
    height: 38px;

}

</style>

