<template>
  <div>
    <categorie></categorie>
  </div>
</template>
<script>
import Categorie from './categorie.vue'
export default {
name: 'catalogue',
components: {
  'categorie' : Categorie,
}
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>


</style>